export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      background: 'transparent',
      padding: '1rem',
      color: 'white',
      '.hamburger': {
        ml: '0.5rem',
        // backgroundColor: 'transparent',
        border: 'none',
        height: '45px',
        div: {
          backgroundColor: 'white'
        },
        'div:nth-of-type(2)': {
          width: '70%'
        }
      },

      '.navItem': {
        color: 'white',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '0.9rem',
          letterSpacing: '0px',
          color: 'white',
          fontWeight: 'bold',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.smallNavMenu': {
        '.navItemDropdownMenu': {
          backgroundColor: 'transparent',
          width: 'fit-content'
        }
      },
      '.order-online': {
        a: {
          color: 'white',
          padding: '0.75rem',
          border: 'solid 1px',
          borderColor: 'white'
        }
      }
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      color: 'white',

      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', '0 auto 0 0'],
        position: ['', '', '', 'static'],
        '.container': {
          // padding: ['0.5rem'],
        },

        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      },

      '.smallNavMenu': {
        '.navItem': {
          color: 'white',
          margin: ['', '', '', '0.75rem', '1rem'],
          a: {
            fontSize: '0.9rem',
            letterSpacing: '0px',
            color: 'white',
            fontWeight: 'bold',
            ':hover': {
              textDecoration: 'underline'
            }
          }
        },
        '.navItemDropdownMenu': {
          backgroundColor: 'black',
          width: 'fit-content'
        },

        '.order-online': {
          a: {
            color: 'white',
            padding: '0.75rem',
            border: 'solid 1px',
            borderColor: 'white'
          }
        }
      },
      '.hamburger': {
        // backgroundColor: 'transparent',
        border: 'none',
        height: '45px',
        div: {
          backgroundColor: 'white'
        },
        'div:nth-of-type(2)': {
          width: '70%'
        }
      }
    },
    '.logoLocationContainer': {
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      padding: '0rem',
      mr: 'auto',

      '.logo': {
        img: {
          filter: 'unset',
          maxHeight: ['70px', '80px', '100px', '120px']
        }
      },
      '.logoScrolled ': {
        img: {
          filter: 'unset',
          maxHeight: ['50px', '60px', '60px', '60px'],
          height: 'unset'
        }
      }
    },

    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      width: ['85%', '60%', '50%', '40%'],
      color: 'text',
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '200px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.navItem': {
        a: {
          fontSize: ['0.9rem', '1rem', '1.25rem', '1.25rem']
          // color: 'text',
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },
      '.navItemDropdownMenu': {
        position: 'static',
        backgroundColor: 'transparent',
        width: 'fit-content'
      },
      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          color: 'text',
          svg: {
            path: { fill: 'text' }
          }
        },
        '.socialContainer': {
          flexDirection: ['', '', '', 'row']
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    maxWidth: 'unset',
    padding: ['3rem 1rem 7rem', '', '2rem 5rem 2rem'],
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    color: 'white',
    '.multiButtonContainer': {
      a: {
        color: 'white'
      }
    },
    '.gonationLogo': {
      width: 'fit-content',
      filter: 'brightness(0) invert(1)'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '2.5rem', '3.5rem', '3.5rem'],
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    marginBottom: '1.5rem',
    order: '1',
    fontWeight: 'bold'
  },
  subtitle: {
    color: 'secondary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'heading',
    textTransform: 'capitalize'
  },
  text: {
    textAlign: 'inherit',
    lineHeight: '1.75',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    order: '3',
    letterSpacing: '1px',
    color: 'black',
    'p:last-child': {
      margin: '0rem'
    }
  },

  borders: {
    '::before': {
      content: "''",
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1638454696/sites/canoe/canoe-logo.png)',
      backgroundSize: '50px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      margin: ['0rem 0rem 1.5rem', '', '0rem 0rem 3rem'],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '1rem',
      height: '50px',
      width: '100%',
      borderBottom: '2px dotted',
      borderColor: 'primary'
    },

    '::after': {
      content: "''",
      order: '6',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1638454696/sites/canoe/canoe-logo.png)',
      backgroundSize: '50px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      margin: '3rem 0rem 0rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '1rem',
      height: '50px',
      width: '100%',
      borderTop: '2px dotted',
      borderColor: 'primary'
    }
  },

  sideBySide1: {
    minHeight: ['', '', '100vh'],
    // backgroundColor: 'white',
    '.lazyload-wrapper': {},
    '.content': {},
    '.title': {
      variant: 'customVariants.title',
      color: 'primary'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  centerBlock: {
    '.section': {
      // variant: 'customVariants.borders',
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: '0rem 0rem 0rem auto',
      maxWidth: ['', '', '50%', '33.3%'],
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: '3rem',
    margin: '0rem auto',
    // width: 'calc(100% - 15vw)',
    '.section': {
      margin: 'auto auto',
      // display: 'none',
      width: '100%',
      maxWidth: '1000px',
      padding: '0.5rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white',
      textShadow: '2px 2px 10px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      color: 'secondary',
      textShadow: '2px 2px 10px #202020',
      display: 'none'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1687963655/sites/the-landing-at-five-twenty/wave.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-10% 105%',
    backgroundSize: ['70%', '', '45%'],
    padding: '10vw 1rem 10vw',
    flexDirection: ['column', 'column', 'column', 'column'],
    alignItems: ['center', 'center', 'center', 'center'],
    textAlign: 'center',
    '.lazyload-wrapper': {
      width: ['100%', '100%', '70%', '70%']
    },
    '.image': {
      // borderBottom: [
      //   'solid 1.5rem white',
      //   'solid 2.5rem white',
      //   'solid 3.5rem white',
      //   'solid 4.5rem white',
      //   'solid 5.5rem white'
      // ]
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3.5rem', '4.5rem', '5.5rem', '6.5rem'],
      textAlign: 'center',
      color: 'primary',
      borderBottom: 'solid 3px'
    },
    '.content': {
      order: 2,
      position: 'relative',
      top: ['-2.5rem', '-3.5rem', '-4.5rem', '-5.5rem', '-6.5rem'],
      color: 'black',
      alignItems: 'center',
      textAlign: 'center',
      background: 'linear-gradient(210deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)'
    },
    '.text': {
      variant: 'customVariants.text',
      mb: '2rem',
      p: {
        textAlign: 'center'
      }
    }
  },

  homepageQuote1: {
    variant: 'customVariants.sideBySide1',
    padding: ['17.5vh 1rem 17.5vh 3rem', '17.5vh 1rem 17.5vh 3rem', '17.5vh 5vw', '17.5vh 20vh', '17.5vh 20vh'],
    position: 'relative',
    '::before': {
      content: "'❝'",
      fontSize: ['5rem', '6rem', '8rem', '10rem'],
      position: 'absolute',
      top: '5vh',
      left: '5vh',
      lineHeight: '0.7',
      color: 'secondary'
    },
    '::after': {
      content: "'❞'",
      fontSize: ['5rem', '6rem', '8rem', '10rem'],
      position: 'absolute',
      bottom: '5vh',
      right: '5vh',
      lineHeight: '0.7',
      color: 'secondary'
    },

    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3.5rem', '4.5rem', '5.5rem', '6.5rem'],
      color: '#2d2d2d',
      textTransform: 'capitalize'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textTransform: 'uppercase'
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    padding: ['0rem', '0rem', '0rem', '0rem', '0rem'],
    minHeight: '100vh',
    '.section': {
      backgroundColor: 'rgba(0,0,0,0.3)',
      maxWidth: 'unset',
      width: '100%',
      padding: ['12.5vh 1rem 12.5vh 3rem', '', '', '12.5vh'],
      '.title': {
        variant: 'customVariants.title',
        display: 'flex',
        color: 'white',
        fontSize: ['2.5rem', '3.5rem', '4.5rem', '5.5rem', '6.5rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        display: 'flex',
        color: '#c4d2d0',
        fontFamily: 'body',
        marginBottom: '1rem',
        letterSpacing: '2px',
        fontSize: ['0.85rem', '0.9rem', '0.95rem', '1rem']
      },
      '.text': {
        variant: 'customVariants.text',
        display: 'flex',
        textAlign: 'center',
        maxWidth: ['100%', '', '70%', '50%'],
        color: 'white',
        '*': {
          textAlign: 'center',
          color: 'white'
        }
      },
      a: {
        order: '4',
        mt: '2rem'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.homepageMenu'
  },

  homepageShout: {
    '.shoutPopUpButton': {
      width: ['', '', '3rem'],
      height: ['', '', '3rem']
    },
    '.text': {
      marginBottom: '0rem'
    },
    '.date': {
      textAlign: 'left'
    },
    '.containerPopUp': {
      border: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    variant: 'customVariants.sideBySide1',
    minHeight: ['50vh', '50vh', '50vh', '50vh'],
    'h2.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    color: 'dark',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      color: 'dark'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'background',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'dark',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'dark'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'primary',
      color: 'white',
      padding: '1rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.4rem', '1.5rem', '1.75rem', '1.75rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ===============================
  // ? ====  private events page  ====
  // ? ===============================
  privateEventsIntro: {
    background: 'unset',
    padding: '6rem 1rem 3rem',
    '.section': {
      border: 'solid 1px black',
      maxWidth: '1000px',
      padding: '2rem'
    }
  },

  privateEventsSection: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
