export default {
  shoutSectionV2: {
    container: {
      width: '100%',
      flexDirection: ['column', 'row'],
      fontSize: [4, 4, 5, 6],
      justifyContent: 'center',
      padding: '2rem 0rem 4rem'
    },
    content: {
      order: '2',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['flex-start', 'flex-start', 'center'],
      padding: 4,
      color: 'text',
      width: ['100%', '50%'],
      flexGrow: '1'
    },
    title: {
      fontSize: '1.25rem',
      margin: '0 auto',
      maxWidth: '500px',
      textAlign: ['left', 'left', 'center'],
      width: '100%',
      borderBottom: '2px solid black',
      paddingBottom: '0.25rem',
      width: 'fit-content',
      textTransform: 'uppercase'
    },
    text: {
      textAlign: ['left', 'left', 'center'],
      maxWidth: '500px',
      margin: '0 auto',
      fontSize: '1em',
      width: '100%'
    },
    date: {
      textAlign: ['left', 'left', 'center'],
      fontSize: '0.75em',
      margin: '1rem auto',
      maxWidth: '500px',
      width: '100%'
    },
    imageContainer: {
      position: 'relative',
      width: ['100%', '50%'],
      flexGrow: '1',
      img: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }
    },
    imageFill: {
      paddingBottom: '70%'
    },
    shoutCTAS: {
      flexWrap: 'wrap',
      marginBottom: '0.5rem',
      transition: 'all ease-in-out 0.3s'
    },
    shoutCTA: {
      margin: '0.5rem 1rem 0.5rem 0rem',
      textDecoration: 'underline',
      fontSize: ['1.25rem', '1.5rem'],
      transition: 'all ease-in-out 0.3s',
      ':hover': {
        color: 'primary'
      }
    }
  }
}
