export default {
  primary: {
    backgroundColor: 'transparent',
    color: 'text',
    border: 'solid 2px',
    borderColor: 'text',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['0.9rem', '', '1rem'],
    fontWeight: '600',
    fontFamily: 'heading',
    transition: 'all ease-in-out 0.5s',
    padding: '0.5rem 1.5rem',
    ':hover': {
      backgroundColor: 'black',
      color: 'white',
      borderColor: 'black'
    }
  },
  secondary: {
    backgroundColor: 'secondary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['0.9rem', '', '1rem'],
    fontFamily: 'body',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
