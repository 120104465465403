export default {
  heading: 'Open Sans, serif',
  body: 'Poiret One, serif',
  monospace: 'Menlo, monospace',
  display: 'Nanum Pen Script, cursive',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Poiret One:100,200,300,400,500,600,700,800,900',
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Italiana',
    'Cinzel',
    'Castoro',
    'Nanum Pen Script'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
