export default {
  contentBoxesV1: {
    container: {
      // padding: '3rem 1rem',
      margin: '2rem 0rem 1rem'
    },
    contentBoxes: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto'
    },
    box: {
      padding: '1rem',
      width: ['', '', '50%'],
      display: 'flex',
      flexDirection: 'column',
      '& :last-child': {
        marginBottom: '0px'
      }
    },
    image: {
      marginBottom: 1,
      height: ['250px'],
      objectFit: 'cover'
    },
    date: {
      marginBottom: 1,
      fontSize: 2,
      fontWeight: 'bold'
    },
    title: {
      marginBottom: 1,
      fontWeight: '500',
      marginBottom: '1rem',
      textTransform: 'uppercase',
      borderBottom: '1px solid'
    },
    subtitle: {
      marginBottom: 1
    },
    text: {
      marginBottom: 1
    }
  }
}
